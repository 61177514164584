import styled from 'styled-components'
import { colors } from 'Common/constants'

export const UnfairTermsContainer = styled.div`
  display: flex;
`
export const UnfairTermsText = styled.p`
  color: ${colors.main};
  text-align: justify;
  padding: 0 16px;
  max-height: 150px;
  overflow-y: scroll;
`
