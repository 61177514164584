import styled from 'styled-components'

export const ConditionContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;

  > button {
    max-width: max-content;
  }
`