import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from "antd"
import { FormattedMessage } from "react-intl"
import { UnfairTermsContainer, UnfairTermsText } from './styled'

const UnfairTerms = (props) => {
  const { isEnabled, onChange } = props
  return (
    <UnfairTermsContainer>
      <UnfairTermsText>
        <FormattedMessage id={'condition.checkbox.unfairTerms'} />
      </UnfairTermsText>
      <Checkbox
        disabled={!isEnabled}
        defaultChecked={!isEnabled}
        onChange={e => onChange(e.target.checked)} >
        <FormattedMessage id={'condition.checkbox.accept'} />
      </Checkbox>
    </UnfairTermsContainer>
  )
}
UnfairTerms.propTypes = {
  isEnabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func
}
export default UnfairTerms