import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Checkbox } from 'antd'

import Button from 'Components/Button'
import { ConditionContainer } from './styled'

const Condition = props => {
  const { id, title, url, isEnabled, onChange, onLinkClick } = props
  const [isLinkClicked, setIsLinkClicked] = useState(false)

  const handleLinkClick = () => {
    setIsLinkClicked(true)
    onLinkClick({ [id]: true })
  }

  return <ConditionContainer>
    <Button type={'link'} onClick={handleLinkClick}>
      <a href={url} target={'_blank'} rel={'noopener noreferrer'}>
        {title}
      </a>
    </Button>
    <Checkbox
      disabled={!isEnabled || !isLinkClicked}
      defaultChecked={!isEnabled}
      onChange={(e) => onChange({ [id]: e.target.checked })}
    >
      <FormattedMessage id={'condition.checkbox.accept'} />
    </Checkbox>
  </ConditionContainer>
}
Condition.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isEnabled: PropTypes.bool,
  onChange: PropTypes.func,
  onLinkClick: PropTypes.func
}
export default Condition